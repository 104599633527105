.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Divider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .tpt-frontend .Divider__innerLine {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .tpt-frontend .Divider__label {
      padding: 0 12px 0 12px;
      white-space: nowrap;
      font-size: 14px;
      text-transform: uppercase; }
    .tpt-frontend .Divider--gray .Divider__innerLine {
      border-bottom: 1px solid #dddddd; }
    .tpt-frontend .Divider--gray .Divider__label {
      color: #777777; }

.tpt-frontend {
  /**
 * This file includes design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, this file should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * This file imports generated design tokens for nicer project imports
 * `@import '~@teachers/design-system/styles/design-tokens.scss'`
 */
  /*
 * This file is intended to tpt-frontend theming overrides for @teachers/design-system.
 * It is required (for now) for webpack to override the theming variables for
 * design-system components during compilation.
 *
 * We add a `tokens` resolve alias in tools/webpack/webpack.common.config.js to achieve this.
 *
 * TODO: this workaround can be removed once we improve theming - see WEB-3453
 */
  /**
 * This file imports generated design tokens for nicer project imports
 * `@import '~@teachers/design-system/styles/design-tokens.scss'`
 */
  /**
 * This file defines mixins that wrap Bootstrap's Reboot "reset"
 * Forked from react-bootstrap v1.0.0-beta.16
 * https://github.com/react-bootstrap/react-bootstrap/tree/v1.0.0-beta.16
 */ }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .tpt-frontend .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
            animation: spinner-border 0.75s linear infinite; }
  .tpt-frontend .spinner-border.text-primary {
    color: #0aa96c !important; }
  .tpt-frontend .spinner-border.text-secondary {
    color: #cccccc !important; }
  .tpt-frontend .spinner-border.text-success {
    color: #0aa96c !important; }
  .tpt-frontend .spinner-border.text-info {
    color: #2885bf !important; }
  .tpt-frontend .spinner-border.text-warning {
    color: #eab536 !important; }
  .tpt-frontend .spinner-border.text-danger {
    color: #c73833 !important; }
  .tpt-frontend .spinner-border.text-light {
    color: #f5f5f5 !important; }
  .tpt-frontend .spinner-border.text-dark {
    color: #777777 !important; }
  .tpt-frontend .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1); } }
  .tpt-frontend .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
            animation: spinner-grow 0.75s linear infinite; }
  .tpt-frontend .spinner-grow.text-primary {
    color: #0aa96c !important; }
  .tpt-frontend .spinner-grow.text-secondary {
    color: #cccccc !important; }
  .tpt-frontend .spinner-grow.text-success {
    color: #0aa96c !important; }
  .tpt-frontend .spinner-grow.text-info {
    color: #2885bf !important; }
  .tpt-frontend .spinner-grow.text-warning {
    color: #eab536 !important; }
  .tpt-frontend .spinner-grow.text-danger {
    color: #c73833 !important; }
  .tpt-frontend .spinner-grow.text-light {
    color: #f5f5f5 !important; }
  .tpt-frontend .spinner-grow.text-dark {
    color: #777777 !important; }
  .tpt-frontend .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .LoadingAnimation {
    display: none;
    border-radius: 0.375em;
    padding: 1em;
    text-align: center; }
    .tpt-frontend .LoadingAnimation__visible {
      display: inline-block; }
    .tpt-frontend .LoadingAnimation__spinnerContainer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 10em;
      height: 10em;
      margin: 0 auto; }
    .tpt-frontend .LoadingAnimation__centered {
      width: 100%; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .text-right,
  .tpt-frontend .Text--alignRight {
    text-align: right; }
  .tpt-frontend .text-center,
  .tpt-frontend .Text--alignCenter {
    text-align: center; }
  .tpt-frontend .text-left,
  .tpt-frontend .Text--alignLeft {
    text-align: left; }
  .tpt-frontend .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .tpt-frontend .text-capitalize {
    text-transform: uppercase !important; }
  .tpt-frontend .Text {
    display: block;
    margin: 0; }
  .tpt-frontend .Text--display {
    font-weight: 400;
    font-size: 48px;
    line-height: 1.2;
    color: #222222; }
  .tpt-frontend .Text--h1 {
    font-weight: 600;
    font-size: 33px;
    line-height: 1.2;
    color: #222222; }
  .tpt-frontend .Text--h2 {
    font-weight: 600;
    font-size: 27px;
    line-height: 1.2;
    color: #222222; }
  .tpt-frontend .Text--h3 {
    font-weight: 600;
    font-size: 23px;
    line-height: 1.2;
    color: #222222; }
  .tpt-frontend .Text--h4 {
    font-weight: 600;
    font-size: 19px;
    line-height: 1.2;
    color: #222222; }
  .tpt-frontend .Text--bodyLarge {
    font-weight: 400;
    font-size: 19px;
    line-height: 1.375;
    color: #222222; }
  .tpt-frontend .Text--body {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.375;
    color: #222222; }
  .tpt-frontend .Text--bodySmall {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.375;
    color: #222222; }
  .tpt-frontend .Text--microcopy {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.375;
    color: #222222; }
  .tpt-frontend .Text--colorError {
    color: #c73833; }
  .tpt-frontend .Text--colorSuccess {
    color: #067c64; }
  .tpt-frontend .Text--colorTextPrimary {
    color: #222222; }
  .tpt-frontend .Text--colorTextSecondary {
    color: #666666; }
  .tpt-frontend .Text--colorTextWhite {
    color: #ffffff; }
  .tpt-frontend .Text--colorInherit {
    color: inherit; }
  .tpt-frontend .Text--inline {
    display: inline; }
  .tpt-frontend .Text--truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .tpt-frontend .Text--strong {
    font-weight: 600; }
  .tpt-frontend .Text--underline {
    text-decoration: underline; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Anchor {
    font-weight: 400;
    cursor: pointer; }
    .tpt-frontend .Anchor:hover {
      text-decoration: underline; }
    .tpt-frontend .Anchor--undecorated:hover {
      text-decoration: none; }
    .tpt-frontend .Anchor--green {
      color: #067c64; }
      .tpt-frontend .Anchor--green:hover {
        color: #067c64; }
    .tpt-frontend .Anchor--black {
      color: #000000; }
    .tpt-frontend .Anchor--gray {
      color: #777777; }
      .tpt-frontend .Anchor--gray:hover {
        color: #000000; }

@font-face {
    font-family: "tpticon";
    font-weight: normal;
    font-style: normal;
    src: url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.4p9d73zkvs.eot);
    src: url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.4p9d73zkvs.eot) format("embedded-opentype"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.rk6kwbejyx.woff2) format("woff2"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.mvyyj37tmh.woff) format("woff"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.qc6pdhje4y.ttf) format("truetype"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.ebxzn1n9j7.svg) format("svg"); }

.tpt-frontend .tpticon {
  display: inline-block;
  font-family: tpticon;
  line-height: 1;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tpt-frontend .tpticon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.tpt-frontend .tpticon-2x {
  font-size: 2em; }

.tpt-frontend .tpticon-3x {
  font-size: 3em; }

.tpt-frontend .tpticon-4x {
  font-size: 4em; }

.tpt-frontend .tpticon-fw {
  width: 1.28571429em;
  text-align: center; }

.tpt-frontend .tpticon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.tpt-frontend .tpticon-ul > li {
  position: relative; }

.tpt-frontend .tpticon-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center; }

.tpt-frontend .tpticon-li.tpticon-lg {
  left: -1.85714286em; }

.tpt-frontend .tpticon-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em; }

.tpt-frontend .tpticon-pull-left {
  float: left; }

.tpt-frontend .tpticon-pull-right {
  float: right; }

.tpt-frontend .tpticon.tpticon-pull-left {
  margin-right: 0.3em; }

.tpt-frontend .tpticon.tpticon-pull-right {
  margin-left: 0.3em; }

.tpt-frontend .tpticon-angle-down::before {
  content: "\ea01"; }

.tpt-frontend .tpticon-angle-left::before {
  content: "\ea02"; }

.tpt-frontend .tpticon-angle-right::before {
  content: "\ea03"; }

.tpt-frontend .tpticon-angle-up::before {
  content: "\ea04"; }

.tpt-frontend .tpticon-archive::before {
  content: "\ea05"; }

.tpt-frontend .tpticon-area-chart::before {
  content: "\ea06"; }

.tpt-frontend .tpticon-balance-scale::before {
  content: "\ea07"; }

.tpt-frontend .tpticon-bookmark::before {
  content: "\ea08"; }

.tpt-frontend .tpticon-bookmark-o::before {
  content: "\ea09"; }

.tpt-frontend .tpticon-calendar::before {
  content: "\ea0a"; }

.tpt-frontend .tpticon-calendar-check-o::before {
  content: "\ea0b"; }

.tpt-frontend .tpticon-caret-down::before {
  content: "\ea0c"; }

.tpt-frontend .tpticon-caret-right::before {
  content: "\ea0d"; }

.tpt-frontend .tpticon-caret-up::before {
  content: "\ea0e"; }

.tpt-frontend .tpticon-chart-line-minimal::before {
  content: "\ea0f"; }

.tpt-frontend .tpticon-check::before {
  content: "\ea10"; }

.tpt-frontend .tpticon-check-circle::before {
  content: "\ea11"; }

.tpt-frontend .tpticon-check-circle-o::before {
  content: "\ea12"; }

.tpt-frontend .tpticon-chevron-left::before {
  content: "\ea13"; }

.tpt-frontend .tpticon-chevron-right::before {
  content: "\ea14"; }

.tpt-frontend .tpticon-circle::before {
  content: "\ea15"; }

.tpt-frontend .tpticon-circle-thin::before {
  content: "\ea16"; }

.tpt-frontend .tpticon-clipboard::before {
  content: "\ea17"; }

.tpt-frontend .tpticon-clock-o::before {
  content: "\ea18"; }

.tpt-frontend .tpticon-close::before {
  content: "\ea19"; }

.tpt-frontend .tpticon-cloud-download::before {
  content: "\ea1a"; }

.tpt-frontend .tpticon-cog::before {
  content: "\ea1b"; }

.tpt-frontend .tpticon-comment-o::before {
  content: "\ea1c"; }

.tpt-frontend .tpticon-commenting::before {
  content: "\ea1d"; }

.tpt-frontend .tpticon-comments-o::before {
  content: "\ea1e"; }

.tpt-frontend .tpticon-copy::before {
  content: "\ea1f"; }

.tpt-frontend .tpticon-credit-card::before {
  content: "\ea20"; }

.tpt-frontend .tpticon-database::before {
  content: "\ea21"; }

.tpt-frontend .tpticon-download::before {
  content: "\ea22"; }

.tpt-frontend .tpticon-ellipsis-v::before {
  content: "\ea23"; }

.tpt-frontend .tpticon-envelope-o::before {
  content: "\ea24"; }

.tpt-frontend .tpticon-exchange::before {
  content: "\ea25"; }

.tpt-frontend .tpticon-exclamation-triangle::before {
  content: "\ea26"; }

.tpt-frontend .tpticon-external-link::before {
  content: "\ea27"; }

.tpt-frontend .tpticon-eye::before {
  content: "\ea28"; }

.tpt-frontend .tpticon-facebook::before {
  content: "\ea29"; }

.tpt-frontend .tpticon-facebook-square::before {
  content: "\ea2a"; }

.tpt-frontend .tpticon-file::before {
  content: "\ea2b"; }

.tpt-frontend .tpticon-file-o::before {
  content: "\ea2c"; }

.tpt-frontend .tpticon-file-text-o::before {
  content: "\ea2d"; }

.tpt-frontend .tpticon-flag-o::before {
  content: "\ea2e"; }

.tpt-frontend .tpticon-folder-open-o::before {
  content: "\ea2f"; }

.tpt-frontend .tpticon-github::before {
  content: "\ea30"; }

.tpt-frontend .tpticon-globe::before {
  content: "\ea31"; }

.tpt-frontend .tpticon-grades::before {
  content: "\ea32"; }

.tpt-frontend .tpticon-graduation-cap::before {
  content: "\ea33"; }

.tpt-frontend .tpticon-heart::before {
  content: "\ea34"; }

.tpt-frontend .tpticon-heart-o::before {
  content: "\ea35"; }

.tpt-frontend .tpticon-home::before {
  content: "\ea36"; }

.tpt-frontend .tpticon-info-circle::before {
  content: "\ea37"; }

.tpt-frontend .tpticon-instagram::before {
  content: "\ea38"; }

.tpt-frontend .tpticon-laptop::before {
  content: "\ea39"; }

.tpt-frontend .tpticon-lightbulb-o::before {
  content: "\ea3a"; }

.tpt-frontend .tpticon-line-chart::before {
  content: "\ea3b"; }

.tpt-frontend .tpticon-link::before {
  content: "\ea3c"; }

.tpt-frontend .tpticon-linkedin::before {
  content: "\ea3d"; }

.tpt-frontend .tpticon-list-ul::before {
  content: "\ea3e"; }

.tpt-frontend .tpticon-lock::before {
  content: "\ea3f"; }

.tpt-frontend .tpticon-long-arrow-right::before {
  content: "\ea40"; }

.tpt-frontend .tpticon-magic::before {
  content: "\ea41"; }

.tpt-frontend .tpticon-money::before {
  content: "\ea42"; }

.tpt-frontend .tpticon-newspaper-o::before {
  content: "\ea43"; }

.tpt-frontend .tpticon-paper-plane::before {
  content: "\ea44"; }

.tpt-frontend .tpticon-pencil::before {
  content: "\ea45"; }

.tpt-frontend .tpticon-pencil-square-o::before {
  content: "\ea46"; }

.tpt-frontend .tpticon-phone::before {
  content: "\ea47"; }

.tpt-frontend .tpticon-pinterest-p::before {
  content: "\ea48"; }

.tpt-frontend .tpticon-pinterest-square::before {
  content: "\ea49"; }

.tpt-frontend .tpticon-play::before {
  content: "\ea4a"; }

.tpt-frontend .tpticon-plus::before {
  content: "\ea4b"; }

.tpt-frontend .tpticon-question::before {
  content: "\ea4c"; }

.tpt-frontend .tpticon-question-circle::before {
  content: "\ea4d"; }

.tpt-frontend .tpticon-question-circle-o::before {
  content: "\ea4e"; }

.tpt-frontend .tpticon-reply::before {
  content: "\ea4f"; }

.tpt-frontend .tpticon-search::before {
  content: "\ea50"; }

.tpt-frontend .tpticon-shopping-cart::before {
  content: "\ea51"; }

.tpt-frontend .tpticon-standards::before {
  content: "\ea52"; }

.tpt-frontend .tpticon-star::before {
  content: "\ea53"; }

.tpt-frontend .tpticon-star-o::before {
  content: "\ea54"; }

.tpt-frontend .tpticon-sticky-note-o::before {
  content: "\ea55"; }

.tpt-frontend .tpticon-tag::before {
  content: "\ea56"; }

.tpt-frontend .tpticon-tags::before {
  content: "\ea57"; }

.tpt-frontend .tpticon-teach-for-justice-badge::before {
  content: "\ea58"; }

.tpt-frontend .tpticon-th-large::before {
  content: "\ea59"; }

.tpt-frontend .tpticon-thumbs-o-up::before {
  content: "\ea5a"; }

.tpt-frontend .tpticon-thumbs-up::before {
  content: "\ea5b"; }

.tpt-frontend .tpticon-times::before {
  content: "\ea5c"; }

.tpt-frontend .tpticon-times-circle::before {
  content: "\ea5d"; }

.tpt-frontend .tpticon-trash-o::before {
  content: "\ea5e"; }

.tpt-frontend .tpticon-twitter::before {
  content: "\ea5f"; }

.tpt-frontend .tpticon-twitter-square::before {
  content: "\ea60"; }

.tpt-frontend .tpticon-user::before {
  content: "\ea61"; }

.tpt-frontend .tpticon-users::before {
  content: "\ea62"; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .CollapsibleContent {
    white-space: pre-wrap; }
    .tpt-frontend .CollapsibleContent__collapsed {
      overflow: hidden; }
    .tpt-frontend .CollapsibleContent__toggle {
      color: #067c64;
      cursor: pointer;
      margin-top: 12px; }
      .tpt-frontend .CollapsibleContent__toggle:hover {
        text-decoration: underline; }
    .tpt-frontend .CollapsibleContent__toggleLabel {
      padding-right: 6px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationStarRating__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    line-height: 1; }
    .tpt-frontend .EvaluationStarRating__wrapper > :first-child {
      margin-right: 6px; }
    .tpt-frontend .EvaluationStarRating__wrapper__categoryWrap {
      display: inline;
      font-size: 12px;
      font-weight: 600;
      font-family: Proxima Nova, "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .tpt-frontend .EvaluationStarRating__subText {
    font-size: 14px; }
    .tpt-frontend .EvaluationStarRating__subText--spacing {
      padding-top: 6px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Avatar__img {
    border-radius: 50%; }
    .tpt-frontend .Avatar__img--small {
      height: 20px;
      width: 20px; }
    .tpt-frontend .Avatar__img--base {
      height: 50px;
      width: 50px; }
    .tpt-frontend .Avatar__img--medium {
      height: 75px;
      width: 75px; }
    .tpt-frontend .Avatar__img--large {
      height: 125px;
      width: 125px; }
    .tpt-frontend .Avatar__img--mobile {
      height: 40px;
      width: 40px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationAvatarByline {
    font-size: 14px;
    margin-bottom: 1em;
    padding-bottom: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .tpt-frontend .EvaluationAvatarByline__byline {
      margin-left: 12px;
      vertical-align: middle; }
    .tpt-frontend .EvaluationAvatarByline__date {
      color: #777777; }
    .tpt-frontend .EvaluationAvatarByline__displayName--bold {
      font-weight: 600; }

.tpt-frontend .responsive .Col--collapsed {
  width: 0;
  padding: 0;
  -webkit-box-flex: 0;
      -ms-flex: 0;
          flex: 0; }

.tpt-frontend .rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  -ms-touch-action: none;
      touch-action: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.tpt-frontend .rc-slider * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.tpt-frontend .rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px; }

.tpt-frontend .rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #abe2fb; }

.tpt-frontend .rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #96dbfa;
  background-color: #fff;
  -ms-touch-action: pan-x;
      touch-action: pan-x; }

.tpt-frontend .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  -webkit-box-shadow: 0 0 0 5px #96dbfa;
          box-shadow: 0 0 0 5px #96dbfa; }

.tpt-frontend .rc-slider-handle:focus {
  outline: none; }

.tpt-frontend .rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  -webkit-box-shadow: unset;
          box-shadow: unset; }

.tpt-frontend .rc-slider-handle:hover {
  border-color: #57c5f7; }

.tpt-frontend .rc-slider-handle:active {
  border-color: #57c5f7;
  -webkit-box-shadow: 0 0 5px #57c5f7;
          box-shadow: 0 0 5px #57c5f7;
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.tpt-frontend .rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px; }

.tpt-frontend .rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.tpt-frontend .rc-slider-mark-text-active {
  color: #666; }

.tpt-frontend .rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.tpt-frontend .rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.tpt-frontend .rc-slider-dot-active {
  border-color: #96dbfa; }

.tpt-frontend .rc-slider-dot-reverse {
  margin-right: -4px; }

.tpt-frontend .rc-slider-disabled {
  background-color: #e9e9e9; }

.tpt-frontend .rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.tpt-frontend .rc-slider-disabled .rc-slider-handle,
.tpt-frontend .rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.tpt-frontend .rc-slider-disabled .rc-slider-mark-text,
.tpt-frontend .rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.tpt-frontend .rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.tpt-frontend .rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.tpt-frontend .rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.tpt-frontend .rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  -ms-touch-action: pan-y;
      touch-action: pan-y; }

.tpt-frontend .rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.tpt-frontend .rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.tpt-frontend .rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.tpt-frontend .rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.tpt-frontend .rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.tpt-frontend .rc-slider-tooltip-zoom-down-enter,
.tpt-frontend .rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.tpt-frontend .rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.tpt-frontend .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.tpt-frontend .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

.tpt-frontend .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

.tpt-frontend .rc-slider-tooltip-zoom-down-enter,
.tpt-frontend .rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.tpt-frontend .rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); } }

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); } }

.tpt-frontend .rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.tpt-frontend .rc-slider-tooltip * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.tpt-frontend .rc-slider-tooltip-hidden {
  display: none; }

.tpt-frontend .rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.tpt-frontend .rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 4px #d9d9d9;
          box-shadow: 0 0 4px #d9d9d9; }

.tpt-frontend .rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tpt-frontend .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .GraphRange {
    width: 100%; }
    .tpt-frontend .GraphRange .rc-slider-disabled {
      background: none;
      cursor: default !important; }
    .tpt-frontend .GraphRange .rc-slider-dot {
      position: absolute;
      margin-left: -1px;
      height: 6px;
      width: 0px;
      bottom: -3px;
      border: 1px solid #dddddd;
      vertical-align: middle;
      border-radius: 0%;
      cursor: default !important; }
    .tpt-frontend .GraphRange .rc-slider-step {
      background: none;
      height: 2px;
      border-color: #dddddd; }
    .tpt-frontend .GraphRange .rc-slider-rail {
      position: absolute;
      width: 100%;
      background-color: #dddddd;
      height: 2px;
      border-radius: 6px;
      top: 6px; }
    .tpt-frontend .GraphRange .rc-slider-handle {
      border-color: none;
      border: 0px solid transparent;
      height: 6px;
      -webkit-box-shadow: none;
              box-shadow: none;
      background-color: none;
      cursor: default;
      position: absolute;
      width: 0px;
      bottom: 4px;
      margin-top: -5px;
      border-radius: 0%; }
    .tpt-frontend .GraphRange .rc-slider-track {
      position: absolute;
      left: 0;
      height: 4px;
      border-radius: 0px; }
    .tpt-frontend .GraphRange__green .rc-slider-track {
      background-color: #0aa96c; }
    .tpt-frontend .GraphRange__red .rc-slider-track {
      background-color: #c73833; }
    .tpt-frontend .GraphRange__gray .rc-slider-track {
      background-color: #777777;
      z-index: 9999; }
    .tpt-frontend .GraphRange__default .rc-slider-track {
      background-color: #2c358b;
      z-index: 1; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EngagementSlider {
    width: 147px;
    height: 80px; }
    .tpt-frontend .EngagementSlider .rc-slider-dot:nth-child(3),
    .tpt-frontend .EngagementSlider :nth-child(4),
    .tpt-frontend .EngagementSlider :nth-child(5),
    .tpt-frontend .EngagementSlider :nth-child(6) {
      border: 0px solid transparent; }
    .tpt-frontend .EngagementSlider .rc-slider-mark-text {
      width: 50px;
      color: #777777;
      cursor: default !important;
      line-height: 1.375; }
    .tpt-frontend .EngagementSlider .rc-slider-mark-text:nth-child(1) {
      text-align: left;
      left: 15% !important;
      margin-left: 2px; }
    .tpt-frontend .EngagementSlider .rc-slider-mark-text:nth-child(2) {
      text-align: right;
      left: 85% !important; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationStudentEngagementSection {
    padding-top: 5px;
    margin-left: 1px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationStudentGradesSection {
    float: left; }
    @media (min-width: 1200px) {
      .tpt-frontend .EvaluationStudentGradesSection {
        width: 190px; } }
    .tpt-frontend .EvaluationStudentGradesSection__grades {
      font-weight: 600; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .StandardSlider {
    width: 25%;
    min-width: 30px;
    float: left;
    vertical-align: middle;
    padding-top: 5px; }
    .tpt-frontend .StandardSlider .rc-slider-dot:nth-child(2),
    .tpt-frontend .StandardSlider :nth-child(3),
    .tpt-frontend .StandardSlider :nth-child(4) {
      border: 0px solid transparent; }
    .tpt-frontend .StandardSlider__notAligned .rc-slider-track {
      width: 2% !important; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationStandardsAlignmentSection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .tpt-frontend .EvaluationStandardsAlignmentSection__standard {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      display: inline-block; }
      .tpt-frontend .EvaluationStandardsAlignmentSection__standard[data-tooltip] {
        white-space: pre-line; }
      .tpt-frontend .EvaluationStandardsAlignmentSection__standard[data-tooltip]::before {
        margin-bottom: -19px; }
      .tpt-frontend .EvaluationStandardsAlignmentSection__standard[data-tooltip]::after {
        min-width: 250px;
        margin-left: -125px;
        margin-bottom: -7px;
        text-align: left;
        z-index: 10000; }
      .tpt-frontend .EvaluationStandardsAlignmentSection__standard__title {
        float: left;
        margin-left: 6px;
        vertical-align: middle; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationDataContainer {
    padding-top: 12px;
    margin-bottom: 6px;
    pointer-events: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media (min-width: 320px) {
      .tpt-frontend .EvaluationDataContainer {
        pointer-events: auto; } }
    .tpt-frontend .EvaluationDataContainer__title {
      color: #666666;
      font-size: 14px;
      margin-bottom: 6px;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .tpt-frontend .EvaluationDataContainer__studentEngagement {
      max-height: 80px;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .tpt-frontend .EvaluationDataContainer__gradesUsedWith {
      height: 100%;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
      @media (min-width: 1200px) {
        .tpt-frontend .EvaluationDataContainer__gradesUsedWith {
          margin-bottom: 0px; } }
    .tpt-frontend .EvaluationDataContainer____standardAlignment {
      height: 100%;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1; }
    .tpt-frontend .EvaluationDataContainer .row {
      width: 100%;
      height: 100%; }
      .tpt-frontend .EvaluationDataContainer .row > * {
        margin-bottom: 24px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EditButton {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-weight: 600;
    color: #067c64; }
    .tpt-frontend .EditButton__icon {
      padding-top: 3px;
      display: inherit;
      margin-right: 4px; }
    .tpt-frontend .EditButton__text {
      display: inherit;
      margin-left: 4px;
      padding-bottom: 2px;
      color: #067c64; }

.tpt-frontend {
  /*
  * IMPORTANT:
  *   This stylelint rule should only be disabled for Design Systems Core UI
  *   as this is the only place where this component is styled.
  *   Please DO NOT disable this in other SCSS files.
*/
  /* stylelint-disable azu/disallow-selector */
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Box {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .tpt-frontend .Box--display-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .tpt-frontend .Box--display-inline {
    display: inline; }
  .tpt-frontend .Box--display-block {
    display: block; }
  .tpt-frontend .Box--display-inline-block {
    display: inline-block; }
  .tpt-frontend .Box--padding-0 {
    padding: 0; }
  .tpt-frontend .Box--padding-right-0 {
    padding-right: 0; }
  .tpt-frontend .Box--padding-left-0 {
    padding-left: 0; }
  .tpt-frontend .Box--padding-top-0 {
    padding-top: 0; }
  .tpt-frontend .Box--padding-bottom-0 {
    padding-bottom: 0; }
  .tpt-frontend .Box--padding-x-0 {
    padding-left: 0;
    padding-right: 0; }
  .tpt-frontend .Box--padding-y-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .tpt-frontend .Box--margin-0 {
    margin: 0; }
  .tpt-frontend .Box--margin-right-0 {
    margin-right: 0; }
  .tpt-frontend .Box--margin-left-0 {
    margin-left: 0; }
  .tpt-frontend .Box--margin-top-0 {
    margin-top: 0; }
  .tpt-frontend .Box--margin-bottom-0 {
    margin-bottom: 0; }
  .tpt-frontend .Box--margin-x-0 {
    margin-left: 0;
    margin-right: 0; }
  .tpt-frontend .Box--margin-y-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .tpt-frontend .Box--padding-none {
    padding: 0; }
  .tpt-frontend .Box--padding-right-none {
    padding-right: 0; }
  .tpt-frontend .Box--padding-left-none {
    padding-left: 0; }
  .tpt-frontend .Box--padding-top-none {
    padding-top: 0; }
  .tpt-frontend .Box--padding-bottom-none {
    padding-bottom: 0; }
  .tpt-frontend .Box--padding-x-none {
    padding-left: 0;
    padding-right: 0; }
  .tpt-frontend .Box--padding-y-none {
    padding-top: 0;
    padding-bottom: 0; }
  .tpt-frontend .Box--margin-none {
    margin: 0; }
  .tpt-frontend .Box--margin-right-none {
    margin-right: 0; }
  .tpt-frontend .Box--margin-left-none {
    margin-left: 0; }
  .tpt-frontend .Box--margin-top-none {
    margin-top: 0; }
  .tpt-frontend .Box--margin-bottom-none {
    margin-bottom: 0; }
  .tpt-frontend .Box--margin-x-none {
    margin-left: 0;
    margin-right: 0; }
  .tpt-frontend .Box--margin-y-none {
    margin-top: 0;
    margin-bottom: 0; }
  .tpt-frontend .Box--padding-xxs {
    padding: 3px; }
  .tpt-frontend .Box--padding-right-xxs {
    padding-right: 3px; }
  .tpt-frontend .Box--padding-left-xxs {
    padding-left: 3px; }
  .tpt-frontend .Box--padding-top-xxs {
    padding-top: 3px; }
  .tpt-frontend .Box--padding-bottom-xxs {
    padding-bottom: 3px; }
  .tpt-frontend .Box--padding-x-xxs {
    padding-left: 3px;
    padding-right: 3px; }
  .tpt-frontend .Box--padding-y-xxs {
    padding-top: 3px;
    padding-bottom: 3px; }
  .tpt-frontend .Box--margin-xxs {
    margin: 3px; }
  .tpt-frontend .Box--margin-right-xxs {
    margin-right: 3px; }
  .tpt-frontend .Box--margin-left-xxs {
    margin-left: 3px; }
  .tpt-frontend .Box--margin-top-xxs {
    margin-top: 3px; }
  .tpt-frontend .Box--margin-bottom-xxs {
    margin-bottom: 3px; }
  .tpt-frontend .Box--margin-x-xxs {
    margin-left: 3px;
    margin-right: 3px; }
  .tpt-frontend .Box--margin-y-xxs {
    margin-top: 3px;
    margin-bottom: 3px; }
  .tpt-frontend .Box--padding-xs {
    padding: 6px; }
  .tpt-frontend .Box--padding-right-xs {
    padding-right: 6px; }
  .tpt-frontend .Box--padding-left-xs {
    padding-left: 6px; }
  .tpt-frontend .Box--padding-top-xs {
    padding-top: 6px; }
  .tpt-frontend .Box--padding-bottom-xs {
    padding-bottom: 6px; }
  .tpt-frontend .Box--padding-x-xs {
    padding-left: 6px;
    padding-right: 6px; }
  .tpt-frontend .Box--padding-y-xs {
    padding-top: 6px;
    padding-bottom: 6px; }
  .tpt-frontend .Box--margin-xs {
    margin: 6px; }
  .tpt-frontend .Box--margin-right-xs {
    margin-right: 6px; }
  .tpt-frontend .Box--margin-left-xs {
    margin-left: 6px; }
  .tpt-frontend .Box--margin-top-xs {
    margin-top: 6px; }
  .tpt-frontend .Box--margin-bottom-xs {
    margin-bottom: 6px; }
  .tpt-frontend .Box--margin-x-xs {
    margin-left: 6px;
    margin-right: 6px; }
  .tpt-frontend .Box--margin-y-xs {
    margin-top: 6px;
    margin-bottom: 6px; }
  .tpt-frontend .Box--padding-sm {
    padding: 12px; }
  .tpt-frontend .Box--padding-right-sm {
    padding-right: 12px; }
  .tpt-frontend .Box--padding-left-sm {
    padding-left: 12px; }
  .tpt-frontend .Box--padding-top-sm {
    padding-top: 12px; }
  .tpt-frontend .Box--padding-bottom-sm {
    padding-bottom: 12px; }
  .tpt-frontend .Box--padding-x-sm {
    padding-left: 12px;
    padding-right: 12px; }
  .tpt-frontend .Box--padding-y-sm {
    padding-top: 12px;
    padding-bottom: 12px; }
  .tpt-frontend .Box--margin-sm {
    margin: 12px; }
  .tpt-frontend .Box--margin-right-sm {
    margin-right: 12px; }
  .tpt-frontend .Box--margin-left-sm {
    margin-left: 12px; }
  .tpt-frontend .Box--margin-top-sm {
    margin-top: 12px; }
  .tpt-frontend .Box--margin-bottom-sm {
    margin-bottom: 12px; }
  .tpt-frontend .Box--margin-x-sm {
    margin-left: 12px;
    margin-right: 12px; }
  .tpt-frontend .Box--margin-y-sm {
    margin-top: 12px;
    margin-bottom: 12px; }
  .tpt-frontend .Box--padding-md {
    padding: 18px; }
  .tpt-frontend .Box--padding-right-md {
    padding-right: 18px; }
  .tpt-frontend .Box--padding-left-md {
    padding-left: 18px; }
  .tpt-frontend .Box--padding-top-md {
    padding-top: 18px; }
  .tpt-frontend .Box--padding-bottom-md {
    padding-bottom: 18px; }
  .tpt-frontend .Box--padding-x-md {
    padding-left: 18px;
    padding-right: 18px; }
  .tpt-frontend .Box--padding-y-md {
    padding-top: 18px;
    padding-bottom: 18px; }
  .tpt-frontend .Box--margin-md {
    margin: 18px; }
  .tpt-frontend .Box--margin-right-md {
    margin-right: 18px; }
  .tpt-frontend .Box--margin-left-md {
    margin-left: 18px; }
  .tpt-frontend .Box--margin-top-md {
    margin-top: 18px; }
  .tpt-frontend .Box--margin-bottom-md {
    margin-bottom: 18px; }
  .tpt-frontend .Box--margin-x-md {
    margin-left: 18px;
    margin-right: 18px; }
  .tpt-frontend .Box--margin-y-md {
    margin-top: 18px;
    margin-bottom: 18px; }
  .tpt-frontend .Box--padding-lg {
    padding: 24px; }
  .tpt-frontend .Box--padding-right-lg {
    padding-right: 24px; }
  .tpt-frontend .Box--padding-left-lg {
    padding-left: 24px; }
  .tpt-frontend .Box--padding-top-lg {
    padding-top: 24px; }
  .tpt-frontend .Box--padding-bottom-lg {
    padding-bottom: 24px; }
  .tpt-frontend .Box--padding-x-lg {
    padding-left: 24px;
    padding-right: 24px; }
  .tpt-frontend .Box--padding-y-lg {
    padding-top: 24px;
    padding-bottom: 24px; }
  .tpt-frontend .Box--margin-lg {
    margin: 24px; }
  .tpt-frontend .Box--margin-right-lg {
    margin-right: 24px; }
  .tpt-frontend .Box--margin-left-lg {
    margin-left: 24px; }
  .tpt-frontend .Box--margin-top-lg {
    margin-top: 24px; }
  .tpt-frontend .Box--margin-bottom-lg {
    margin-bottom: 24px; }
  .tpt-frontend .Box--margin-x-lg {
    margin-left: 24px;
    margin-right: 24px; }
  .tpt-frontend .Box--margin-y-lg {
    margin-top: 24px;
    margin-bottom: 24px; }
  .tpt-frontend .Box--padding-xl {
    padding: 30px; }
  .tpt-frontend .Box--padding-right-xl {
    padding-right: 30px; }
  .tpt-frontend .Box--padding-left-xl {
    padding-left: 30px; }
  .tpt-frontend .Box--padding-top-xl {
    padding-top: 30px; }
  .tpt-frontend .Box--padding-bottom-xl {
    padding-bottom: 30px; }
  .tpt-frontend .Box--padding-x-xl {
    padding-left: 30px;
    padding-right: 30px; }
  .tpt-frontend .Box--padding-y-xl {
    padding-top: 30px;
    padding-bottom: 30px; }
  .tpt-frontend .Box--margin-xl {
    margin: 30px; }
  .tpt-frontend .Box--margin-right-xl {
    margin-right: 30px; }
  .tpt-frontend .Box--margin-left-xl {
    margin-left: 30px; }
  .tpt-frontend .Box--margin-top-xl {
    margin-top: 30px; }
  .tpt-frontend .Box--margin-bottom-xl {
    margin-bottom: 30px; }
  .tpt-frontend .Box--margin-x-xl {
    margin-left: 30px;
    margin-right: 30px; }
  .tpt-frontend .Box--margin-y-xl {
    margin-top: 30px;
    margin-bottom: 30px; }
  .tpt-frontend .Box--padding-xxl {
    padding: 60px; }
  .tpt-frontend .Box--padding-right-xxl {
    padding-right: 60px; }
  .tpt-frontend .Box--padding-left-xxl {
    padding-left: 60px; }
  .tpt-frontend .Box--padding-top-xxl {
    padding-top: 60px; }
  .tpt-frontend .Box--padding-bottom-xxl {
    padding-bottom: 60px; }
  .tpt-frontend .Box--padding-x-xxl {
    padding-left: 60px;
    padding-right: 60px; }
  .tpt-frontend .Box--padding-y-xxl {
    padding-top: 60px;
    padding-bottom: 60px; }
  .tpt-frontend .Box--margin-xxl {
    margin: 60px; }
  .tpt-frontend .Box--margin-right-xxl {
    margin-right: 60px; }
  .tpt-frontend .Box--margin-left-xxl {
    margin-left: 60px; }
  .tpt-frontend .Box--margin-top-xxl {
    margin-top: 60px; }
  .tpt-frontend .Box--margin-bottom-xxl {
    margin-bottom: 60px; }
  .tpt-frontend .Box--margin-x-xxl {
    margin-left: 60px;
    margin-right: 60px; }
  .tpt-frontend .Box--margin-y-xxl {
    margin-top: 60px;
    margin-bottom: 60px; }
  .tpt-frontend .Box--padding-auto {
    padding: auto; }
  .tpt-frontend .Box--padding-right-auto {
    padding-right: auto; }
  .tpt-frontend .Box--padding-left-auto {
    padding-left: auto; }
  .tpt-frontend .Box--padding-top-auto {
    padding-top: auto; }
  .tpt-frontend .Box--padding-bottom-auto {
    padding-bottom: auto; }
  .tpt-frontend .Box--padding-x-auto {
    padding-left: auto;
    padding-right: auto; }
  .tpt-frontend .Box--padding-y-auto {
    padding-top: auto;
    padding-bottom: auto; }
  .tpt-frontend .Box--margin-auto {
    margin: auto; }
  .tpt-frontend .Box--margin-right-auto {
    margin-right: auto; }
  .tpt-frontend .Box--margin-left-auto {
    margin-left: auto; }
  .tpt-frontend .Box--margin-top-auto {
    margin-top: auto; }
  .tpt-frontend .Box--margin-bottom-auto {
    margin-bottom: auto; }
  .tpt-frontend .Box--margin-x-auto {
    margin-left: auto;
    margin-right: auto; }
  .tpt-frontend .Box--margin-y-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .tpt-frontend .Box--overflow-hidden {
    overflow: hidden; }
  .tpt-frontend .Box--display-visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
            clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; }

.tpt-frontend {
  /*
  * IMPORTANT:
  *   This stylelint rule should only be disabled for Design Systems Core UI
  *   as this is the only place where this component is styled.
  *   Please DO NOT disable this in other SCSS files.
*/
  /* stylelint-disable azu/disallow-selector */
  /* Fix for flex items that won't shrink below their minimum content size
 * https://github.com/philipwalton/flexbugs#flexbug-1
 */ }
  .tpt-frontend .FlexBox--flex-grow {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    /* 1 */
    min-height: 0;
    min-width: 0; }
  .tpt-frontend .FlexBox--flex-none {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto; }
  .tpt-frontend .FlexBox--flexWrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .tpt-frontend .FlexBox--flexDirection-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .tpt-frontend .FlexBox--flexDirection-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; }
  .tpt-frontend .FlexBox--flexDirection-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .tpt-frontend .FlexBox--flexDirection-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }
  .tpt-frontend .FlexBox--justifyContent-flex-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .tpt-frontend .FlexBox--justifyContent-flex-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .tpt-frontend .FlexBox--justifyContent-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .tpt-frontend .FlexBox--justifyContent-space-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .tpt-frontend .FlexBox--justifyContent-space-around {
    -ms-flex-pack: distribute;
        justify-content: space-around; }
  .tpt-frontend .FlexBox--alignItems-flex-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .tpt-frontend .FlexBox--alignItems-flex-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .tpt-frontend .FlexBox--alignItems-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .tpt-frontend .FlexBox--alignItems-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline; }
  .tpt-frontend .FlexBox--alignItems-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
  .tpt-frontend .FlexBox--alignContent-flex-start {
    -ms-flex-line-pack: start;
        align-content: flex-start; }
  .tpt-frontend .FlexBox--alignContent-flex-end {
    -ms-flex-line-pack: end;
        align-content: flex-end; }
  .tpt-frontend .FlexBox--alignContent-center {
    -ms-flex-line-pack: center;
        align-content: center; }
  .tpt-frontend .FlexBox--alignContent-space-between {
    -ms-flex-line-pack: justify;
        align-content: space-between; }
  .tpt-frontend .FlexBox--alignContent-space-around {
    -ms-flex-line-pack: distribute;
        align-content: space-around; }
  .tpt-frontend .FlexBox--alignSelf-auto {
    -ms-flex-item-align: auto;
        align-self: auto; }
  .tpt-frontend .FlexBox--alignSelf-flex-start {
    -ms-flex-item-align: start;
        align-self: flex-start; }
  .tpt-frontend .FlexBox--alignSelf-flex-end {
    -ms-flex-item-align: end;
        align-self: flex-end; }
  .tpt-frontend .FlexBox--alignSelf-center {
    -ms-flex-item-align: center;
        align-self: center; }
  .tpt-frontend .FlexBox--alignSelf-stretch {
    -ms-flex-item-align: stretch;
        align-self: stretch; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }

@-webkit-keyframes progress-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes progress-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .tpt-frontend .Spinner {
    display: inline-block;
    line-height: 1;
    color: #cccccc; }
    .tpt-frontend .Spinner__svg {
      -webkit-animation: progress-spinner-rotate 600ms linear infinite;
              animation: progress-spinner-rotate 600ms linear infinite; }
    .tpt-frontend .Spinner--primary {
      color: #067c64; }
    .tpt-frontend .Spinner--white {
      color: #ffffff; }
    .tpt-frontend .Spinner__circle {
      stroke: currentColor; }

.tpt-frontend {
  /*
  * IMPORTANT:
  *   This stylelint rule should only be disabled for Design Systems Core UI
  *   as this is the only place where this component is styled.
  *   Please DO NOT disable this in other SCSS files.
*/
  /* stylelint-disable azu/disallow-selector */
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */
  /* BASE STYLES */
  /* SIZES */
  /* STATES */
  /* ICONS */
  /* VARIANTS */
  /* BASE STYLES */
  /* GENERATE VARIANTS */
  /* CONTAINED AND UNCONTAINED STYLES */
  /* SIZES */
  /* STATES, LOADING, DISABLED, ETC */ }
  .tpt-frontend .DSButton {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: none;
    border-radius: 3px;
    line-height: 1;
    display: inline-block;
    padding: 0;
    -webkit-transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
    position: relative;
    text-align: center;
    margin: 0;
    overflow: visible;
    text-transform: none;
    white-space: nowrap;
    text-decoration: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    /* CHILD ELEMENTS */ }
    .tpt-frontend .DSButton__content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      position: relative;
      -webkit-transition: color 200ms ease-in-out;
      transition: color 200ms ease-in-out; }
    .tpt-frontend .DSButton__text {
      font-family: Proxima Nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 600; }
    .tpt-frontend .DSButton__icon {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 16px; }
    .tpt-frontend .DSButton__loader, .tpt-frontend .DSButton__icon--left {
      margin-right: 0.5em; }
    .tpt-frontend .DSButton__icon--right {
      margin-left: 0.5em; }
  .tpt-frontend .DSButton--primary {
    background-color: #067c64;
    border: 1px solid #067c64; }
    .tpt-frontend .DSButton--primary .DSButton__content {
      color: #ffffff; }
    .tpt-frontend .DSButton--primary:not(:disabled):hover {
      border-color: #055f4c;
      background-color: #055f4c; }
    .tpt-frontend .DSButton--primary:not(:disabled):active {
      border-color: #034235; }
  .tpt-frontend .DSButton--secondary {
    background-color: #ffffff;
    border: 1px solid #cccccc; }
    .tpt-frontend .DSButton--secondary .DSButton__content {
      color: #666666; }
    .tpt-frontend .DSButton--secondary:not(:disabled):hover {
      border-color: #067c64;
      background-color: #ffffff; }
      .tpt-frontend .DSButton--secondary:not(:disabled):hover .DSButton__content {
        color: #067c64; }
    .tpt-frontend .DSButton--secondary:not(:disabled):active {
      border-color: #055f4c; }
      .tpt-frontend .DSButton--secondary:not(:disabled):active .DSButton__content {
        color: #055f4c; }
  .tpt-frontend .DSButton--destructive {
    background-color: #c73833;
    border: 1px solid transparent; }
    .tpt-frontend .DSButton--destructive .DSButton__content {
      color: #ffffff; }
    .tpt-frontend .DSButton--destructive:not(:disabled):hover {
      background-color: #af312d; }
  .tpt-frontend .DSButton--tertiary .DSButton__content {
    color: #067c64; }
  .tpt-frontend .DSButton--tertiary:not(:disabled):hover .DSButton__content {
    color: #067c64; }
  .tpt-frontend .DSButton--tertiary:not(:disabled):hover .DSButton__text {
    text-decoration: underline; }
  .tpt-frontend .DSButton--tertiary:not(:disabled):active .DSButton__content {
    color: #055f4c; }
  .tpt-frontend .DSButton--tertiary.DSButton--disabled, .tpt-frontend .DSButton--tertiary.DSButton:disabled {
    background: none;
    border: none; }
  .tpt-frontend .DSButton--tertiary-destructive .DSButton__content {
    color: #c73833; }
  .tpt-frontend .DSButton--tertiary-destructive:not(:disabled):hover .DSButton__content {
    color: #c73833; }
  .tpt-frontend .DSButton--tertiary-destructive:not(:disabled):active .DSButton__content {
    color: #962a27; }
  .tpt-frontend .DSButton--expressive {
    background-color: #1c79cd;
    border: 1px solid transparent; }
    .tpt-frontend .DSButton--expressive .DSButton__content {
      color: #ffffff; }
    .tpt-frontend .DSButton--expressive:not(:disabled):hover {
      background-color: #105f9e; }
  .tpt-frontend .DSButton--primaryOutline {
    background-color: transparent;
    border: 1px solid #067c64; }
    .tpt-frontend .DSButton--primaryOutline .DSButton__content {
      color: #067c64; }
    .tpt-frontend .DSButton--primaryOutline:not(:disabled):hover {
      border-color: #055f4c;
      background-color: transparent; }
    .tpt-frontend .DSButton--primaryOutline:not(:disabled):active {
      border-color: #034235; }
  .tpt-frontend .DSButton {
    min-height: auto; }
    .tpt-frontend .DSButton--contained {
      min-width: 54px; }
    .tpt-frontend .DSButton--uncontained {
      background-color: transparent;
      border: none;
      padding: 0; }
    .tpt-frontend .DSButton--fullWidth {
      width: 100%;
      display: block; }
  .tpt-frontend .DSButton--small .DSButton__text,
  .tpt-frontend .DSButton--small .DSButton__icon {
    font-size: 14px; }
  .tpt-frontend .DSButton--large .DSButton__text,
  .tpt-frontend .DSButton--large .DSButton__icon {
    font-size: 18px; }
  .tpt-frontend .DSButton--contained.DSButton--small {
    line-height: 1;
    min-height: 30px;
    padding: 6px 12px; }
    .tpt-frontend .DSButton--contained.DSButton--small::before, .tpt-frontend .DSButton--contained.DSButton--small::after {
      content: '';
      display: block;
      height: 0;
      width: 0; }
    .tpt-frontend .DSButton--contained.DSButton--small::before {
      margin-bottom: calc(-0.05435em + 0px); }
    .tpt-frontend .DSButton--contained.DSButton--small::after {
      margin-top: calc(-0.1413em + 0px); }
  .tpt-frontend .DSButton--contained.DSButton--medium {
    min-height: 42px;
    padding: 12px 18px; }
  .tpt-frontend .DSButton--contained.DSButton--large {
    min-height: 54px;
    padding: 12px 30px; }
  .tpt-frontend .DSButton--loading, .tpt-frontend .DSButton--disabled, .tpt-frontend .DSButton:disabled {
    background-color: #f5f5f5;
    border: 1px solid #dddddd;
    cursor: not-allowed; }
    .tpt-frontend .DSButton--loading .DSButton__text,
    .tpt-frontend .DSButton--loading .DSButton__icon, .tpt-frontend .DSButton--disabled .DSButton__text,
    .tpt-frontend .DSButton--disabled .DSButton__icon, .tpt-frontend .DSButton:disabled .DSButton__text,
    .tpt-frontend .DSButton:disabled .DSButton__icon {
      color: #cccccc; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EditReplyButton {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-top: 18px; }
    .tpt-frontend .EditReplyButton .DSButton__text {
      font-weight: 600;
      font-size: 14px;
      text-decoration: none !important; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationSellerReply .EvaluationAvatarByline {
    margin-bottom: 3px;
    padding-bottom: 0px; }
    .tpt-frontend .EvaluationSellerReply .EvaluationAvatarByline__byline {
      margin-left: 0px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .ReplyButton {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 24px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .CommentTextField {
    width: 100%;
    height: 100%;
    outline: none;
    min-height: 116px;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    background-color: #ffffff;
    margin-bottom: 6px; }
  .tpt-frontend .CommentTextField__tall {
    height: 15em; }
    @media (min-width: 580px) {
      .tpt-frontend .CommentTextField__tall {
        height: 100%; } }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Button {
    border-radius: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    line-height: 1;
    cursor: pointer;
    font-weight: 600;
    font-family: Proxima Nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center; }
    .tpt-frontend .Button:disabled {
      background: #dddddd;
      border: 1px solid transparent;
      color: #777777;
      cursor: not-allowed; }
      .tpt-frontend .Button:disabled:hover {
        background: #dddddd;
        border: 1px solid transparent;
        color: #777777; }
  .tpt-frontend .Button--primary {
    background: #0aa96c;
    border: 1px solid transparent;
    color: #ffffff; }
    .tpt-frontend .Button--primary:hover {
      background: #07794d; }
  .tpt-frontend .Button--small {
    padding: 3px 6px;
    font-size: 14px;
    line-height: 1.5; }
    .tpt-frontend .Button--small .Button__iconLeft {
      margin-right: 6px; }
    .tpt-frontend .Button--small .Button__iconRight {
      margin-left: 6px; }
  .tpt-frontend .Button--primaryLink, .tpt-frontend .Button--whiteLink {
    background-color: transparent;
    color: #777777; }
    .tpt-frontend .Button--primaryLink:hover, .tpt-frontend .Button--whiteLink:hover {
      text-decoration: underline;
      background-color: transparent; }
  .tpt-frontend .Button--fullWidth {
    width: 100%; }
  .tpt-frontend .Button--medium {
    padding: 12px 18px;
    font-size: 16px; }
    .tpt-frontend .Button--medium .Button__iconLeft {
      margin-right: 12px; }
    .tpt-frontend .Button--medium .Button__iconRight {
      margin-left: 12px; }
  .tpt-frontend .Button--link {
    display: inline-block; }
  .tpt-frontend .Button--primaryOutline {
    background: transparent;
    border: 1px solid #067c64;
    color: #067c64; }
    .tpt-frontend .Button--primaryOutline:hover {
      border-color: #044b3d;
      color: #044b3d; }
  .tpt-frontend .Button--primaryLink {
    color: #067c64; }
  .tpt-frontend .Button--secondary {
    border: 1px solid transparent;
    background: #cccccc;
    color: #222222; }
    .tpt-frontend .Button--secondary:hover {
      background: #5e5e5e;
      color: #ffffff; }
  .tpt-frontend .Button--secondaryOutline {
    background: #ffffff;
    border: 1px solid #dddddd;
    color: #666666; }
    .tpt-frontend .Button--secondaryOutline:hover {
      border-color: #c4c4c4;
      color: #090909; }
  .tpt-frontend .Button--tertiary {
    border: 1px solid transparent;
    background: transparent;
    color: #067c64; }
  .tpt-frontend .Button--white {
    background: #ffffff;
    border: 1px solid transparent;
    color: inherit; }
    .tpt-frontend .Button--white:hover {
      background: rgba(0, 0, 0, 0.4);
      color: #ffffff; }
  .tpt-frontend .Button--whiteOutline {
    border: 1px solid #ffffff;
    background: transparent;
    color: #ffffff; }
    .tpt-frontend .Button--whiteOutline:hover {
      border-color: rgba(0, 0, 0, 0.4); }
  .tpt-frontend .Button--red {
    background: #c73833;
    border: 1px solid transparent;
    color: #ffffff; }
    .tpt-frontend .Button--red:hover {
      background: #9e2d29; }
  .tpt-frontend .Button--redLink {
    background-color: transparent;
    color: #c73833; }
    .tpt-frontend .Button--redLink:hover {
      text-decoration: underline;
      background-color: transparent; }
  .tpt-frontend .Button--gray {
    border: 1px solid transparent;
    background-color: #cccccc;
    color: #666666; }
    .tpt-frontend .Button--gray:hover {
      background-color: gray(dark);
      color: #ffffff; }
  .tpt-frontend .Button--grayLink {
    border: none;
    background-color: #ffffff;
    color: #777777; }
    .tpt-frontend .Button--grayLink:hover {
      color: #666666; }
  .tpt-frontend .Button--grayOutline {
    border: 1px solid #dddddd;
    background-color: #ffffff;
    color: #777777; }
    .tpt-frontend .Button--grayOutline:hover {
      border-color: #222222;
      color: #222222; }
  .tpt-frontend .Button--highlight {
    background-color: #2d9cdb;
    color: #ffffff; }
    .tpt-frontend .Button--highlight:hover {
      background-color: #0a71b4; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  @media (min-width: 580px) {
    .tpt-frontend .ReplyForm__buttons > :first-child {
      margin-left: 6px; } }
  .tpt-frontend .ReplyForm__buttons > * {
    margin-top: 12px;
    width: 100%; }
    @media (min-width: 580px) {
      .tpt-frontend .ReplyForm__buttons > * {
        margin-top: 0;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content; } }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .ReplyContainer {
    margin-top: 30px;
    width: 100%; }
    .tpt-frontend .ReplyContainer__avatar {
      padding-top: 12px;
      padding-right: 12px; }
      @media (min-width: 580px) {
        .tpt-frontend .ReplyContainer__avatar {
          margin-left: 12px; } }
      @media (min-width: 980px) {
        .tpt-frontend .ReplyContainer__avatar {
          margin-left: 60px; } }
    .tpt-frontend .ReplyContainer__content {
      border: 1px solid #dddddd;
      border-radius: 8px;
      font-size: 16px;
      overflow: hidden;
      padding: 18px;
      width: 100%; }
      .tpt-frontend .ReplyContainer__content--reply {
        background-color: #f5f5f5;
        padding-bottom: 12px; }
      @media (min-width: 580px) {
        .tpt-frontend .ReplyContainer__content {
          padding: 24px; } }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .MarkAsHelpfulButton {
    min-height: 30px; }
    .tpt-frontend .MarkAsHelpfulButton .DSButton__content,
    .tpt-frontend .MarkAsHelpfulButton .DSButton--tertiary:not(:disabled):hover {
      color: #000000 !important; }
    .tpt-frontend .MarkAsHelpfulButton--reviewer {
      cursor: default; }
      .tpt-frontend .MarkAsHelpfulButton--reviewer .DSButton__content,
      .tpt-frontend .MarkAsHelpfulButton--reviewer .DSButton--tertiary:not(:disabled):hover {
        color: #666666 !important; }
    .tpt-frontend .MarkAsHelpfulButton .DSButton__text {
      font-weight: 400;
      font-size: 14px;
      text-decoration: none !important; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .MarkAsHelpfulContainer {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 30px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationDisplay__content {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 16px; }
  .tpt-frontend .EvaluationDisplay__link {
    margin-left: auto;
    margin-bottom: 18px;
    vertical-align: middle; }
  .tpt-frontend .EvaluationDisplay__actions {
    margin-top: 30px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationCategoryStarRating {
    width: 200px;
    display: inline-block; }
    .tpt-frontend .EvaluationCategoryStarRating__category {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .tpt-frontend .EvaluationCategoryStarRating__category__categoryName {
        display: inline; }
      .tpt-frontend .EvaluationCategoryStarRating__category .EvaluationStarRating {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1; }
        .tpt-frontend .EvaluationCategoryStarRating__category .EvaluationStarRating__wrapper {
          float: right; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationStarRatingList__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .tpt-frontend .EvaluationStarRatingList__text {
    margin-left: 6px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .EvaluationLegacyDisplay__content {
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 16px; }
  .tpt-frontend .EvaluationLegacyDisplay__ratings__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .tpt-frontend .EvaluationLegacyDisplay__ratings__text__link {
      margin-left: 3px;
      cursor: pointer;
      color: #067c64; }
  .tpt-frontend .EvaluationLegacyDisplay__ratings__list {
    margin-top: 12px; }
  .tpt-frontend .EvaluationLegacyDisplay__link {
    margin-left: auto;
    margin-bottom: 18px;
    vertical-align: middle; }
  .tpt-frontend .EvaluationLegacyDisplay__actions {
    margin-top: 30px; }
  .tpt-frontend .EvaluationLegacyDisplay .EvaluationStarRating__wrapper__categoryWrap__category {
    font-size: 14px;
    font-weight: 400; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Comments-flx-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .tpt-frontend .Comments--flx-h {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .tpt-frontend .Comments--textAlign-center {
    text-align: center; }
  .tpt-frontend .Comments--flx-vr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse; }
  .tpt-frontend .Comments--alignItems-flexEnd {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .tpt-frontend .Comments--alignSelf-flexEnd {
    -ms-flex-item-align: end;
        align-self: flex-end; }
  .tpt-frontend .Comments--jc-flexEnd {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end; }
  .tpt-frontend .Comments--jc-SpaceBetween {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .tpt-frontend .Comments--margin-top-sm {
    margin-top: 12px; }
  .tpt-frontend .Comments--margin-top-md {
    margin-top: 18px; }
  .tpt-frontend .Comments--margin-top-lg {
    margin-top: 24px; }
  .tpt-frontend .Comments--margin-top-xl {
    margin-top: 30px; }
  .tpt-frontend .Comments--margin-top-xxl {
    margin-top: 60px; }
  .tpt-frontend .Comments--margin-bottom-md {
    margin-bottom: 18px; }
  .tpt-frontend .Comments--margin-bottom-xl {
    margin-bottom: 30px; }
  .tpt-frontend .Comments--margin-bottom-xxxl {
    margin-bottom: 120px; }
  .tpt-frontend .Comments--margin-right-sm {
    margin-right: 12px; }
  .tpt-frontend .Comments--margin-right-lg {
    margin-right: 24px; }
  .tpt-frontend .Tabs {
    margin-top: 12px; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .StoreCommentsContainer {
    padding: 22px; }
    .tpt-frontend .StoreCommentsContainer__title {
      font-size: 16px;
      font-weight: 400; }
    .tpt-frontend .StoreCommentsContainer__comment + .StoreCommentsContainer__comment {
      margin-top: 24px;
      font-weight: 400; }
    .tpt-frontend .StoreCommentsContainer__noCommentsPadding {
      padding-bottom: 235px; }
    .tpt-frontend .StoreCommentsContainer .EvaluationDisplay__link, .tpt-frontend .StoreCommentsContainer__loadMore, .tpt-frontend .StoreCommentsContainer__loadMore-button {
      padding-top: 18px; }

